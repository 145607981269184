import type { LucideIcon } from "lucide-react"
import {
	BoxesIcon,
	CheckSquareIcon,
	ClockIcon,
	DollarSignIcon,
	FileIcon,
	FuelIcon,
	GemIcon,
	HammerIcon,
	HomeIcon,
	LayoutIcon,
	MapPinIcon,
	PackageIcon,
	ShieldIcon,
	TruckIcon,
	UserIcon,
	UsersIcon,
	WrenchIcon,
} from "lucide-react"

type FeatureConfig = Record<
	string,
	{
		labels: {
			navigation: string
			singular: string
			plural: string
			abbreviation: string
		}
		icon: LucideIcon
	}
>
export const featureConfig = {
	customer: {
		labels: {
			navigation: "Customers",
			singular: "Customer",
			plural: "Customers",
			abbreviation: "Cust",
		},
		icon: UserIcon,
	},
	dashboard: {
		labels: {
			navigation: "Dashboard",
			singular: "Dashboard",
			plural: "Dashboard",
			abbreviation: "Dash",
		},
		icon: LayoutIcon,
	},
	employee: {
		labels: {
			navigation: "Employees",
			singular: "Employee",
			plural: "Employees",
			abbreviation: "Emp",
		},
		icon: UsersIcon,
	},
	vendor: {
		labels: {
			navigation: "Vendors",
			singular: "Vendor",
			plural: "Vendors",
			abbreviation: "Vend",
		},
		icon: PackageIcon,
	},
	workOrder: {
		labels: {
			navigation: "Work Orders",
			singular: "Work Order",
			plural: "Work Orders",
			abbreviation: "WO",
		},
		icon: WrenchIcon,
	},
	part: {
		labels: {
			navigation: "Parts",
			singular: "Part",
			plural: "Parts",
			abbreviation: "Part",
		},
		icon: HammerIcon,
	},
	maintenance: {
		labels: {
			navigation: "Maintenance",
			singular: "Maintenance",
			plural: "Maintenance",
			abbreviation: "Maint",
		},
		icon: CheckSquareIcon,
	},
	shopClock: {
		labels: {
			navigation: "Shop Clock",
			singular: "Shop Clock",
			plural: "Shop Clock",
			abbreviation: "Shop",
		},
		icon: ClockIcon,
	},
	fuel: {
		labels: {
			navigation: "Fuel",
			singular: "Fuel",
			plural: "Fuel",
			abbreviation: "Fuel",
		},
		icon: FuelIcon,
	},
	financial: {
		labels: {
			navigation: "Financial",
			singular: "Financial",
			plural: "Financial",
			abbreviation: "Fin",
		},
		icon: DollarSignIcon,
	},
	inventory: {
		labels: {
			navigation: "Inventory",
			singular: "Inventory",
			plural: "Inventory",
			abbreviation: "Inv",
		},
		icon: BoxesIcon,
	},
	equipment: {
		labels: {
			navigation: "Equipment",
			singular: "Equipment",
			plural: "Equipment",
			abbreviation: "Equip",
		},
		icon: TruckIcon,
	},
	service: {
		labels: {
			navigation: "Service",
			singular: "Service",
			plural: "Services",
			abbreviation: "Serv",
		},
		icon: WrenchIcon,
	},
	location: {
		labels: {
			navigation: "Locations",
			singular: "Location",
			plural: "Locations",
			abbreviation: "Loc",
		},
		icon: MapPinIcon,
	},
	report: {
		labels: {
			navigation: "Reports",
			singular: "Report",
			plural: "Reports",
			abbreviation: "Rep",
		},
		icon: FileIcon,
	},
	user: {
		labels: {
			navigation: "Users",
			singular: "User",
			plural: "Users",
			abbreviation: "Usr",
		},
		icon: UserIcon,
	},

	//THESE FEATURES WILL BE REMOVED:
	home: {
		labels: {
			navigation: "Home",
			singular: "Home",
			plural: "Home",
			abbreviation: "Home",
		},
		icon: HomeIcon,
	},
	component: {
		labels: {
			navigation: "Components",
			singular: "Component",
			plural: "Components",
			abbreviation: "Comp",
		},
		icon: LayoutIcon,
	},
	sentry: {
		labels: {
			navigation: "Sentry",
			singular: "Sentry",
			plural: "Sentry",
			abbreviation: "Sentry",
		},
		icon: ShieldIcon,
	},
	deployment: {
		labels: {
			navigation: "Deployment",
			singular: "Deployment",
			plural: "Deployment",
			abbreviation: "Deploy",
		},
		icon: GemIcon,
	},
} as const satisfies FeatureConfig

export type Feature = keyof typeof featureConfig
