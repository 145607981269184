import { Suspense } from "react"
import { QueryErrorResetBoundary } from "@tanstack/react-query"
import { ErrorBoundary } from "react-error-boundary"
import { ClientOnly } from "remix-utils/client-only"

import { detailsViewConfig } from "@/config/details-view"
import { Button } from "@/components/ui/button"
import { Skeleton } from "@/components/ui/skeleton"

import { useDetailsState } from "./use-details-state"

const DetailsSkeleton = () => {
	return (
		<div className="space-y-4 p-6">
			<Skeleton className="h-6 w-32" />
			<Skeleton className="h-4 w-full" />
			<Skeleton className="h-4 w-3/4" />
			<Skeleton className="h-4 w-1/2" />
		</div>
	)
}

const ErrorFallback = ({
	resetErrorBoundary,
}: {
	resetErrorBoundary: () => void
}) => (
	<div className="space-y-4 p-6">
		<p className="text-lg font-semibold">Unable to Load Details</p>
		<p className="text-sm text-foreground-weak">
			An unexpected error occurred while loading the details. Please try
			again.
		</p>
		<Button onClick={resetErrorBoundary} variant="outline">
			Retry
		</Button>
	</div>
)

/**
 * Details component that handles displaying single or multiple detail views
 * with error boundaries, loading states, and client-only rendering
 */
export const Details = () => {
	const details = useDetailsState()
	// Early return if no details to show
	if (!details.isOpen) return null

	if (details.view === "error" || !details.view) {
		return (
			<div className="grid size-full place-items-center text-foreground-weak">
				Invalid view
			</div>
		)
	}

	const DetailViewComponent = detailsViewConfig[details.view]

	return (
		<ClientOnly>
			{() => (
				<QueryErrorResetBoundary>
					{({ reset }) => (
						<ErrorBoundary
							onReset={reset}
							fallbackRender={({ resetErrorBoundary }) => (
								<ErrorFallback
									resetErrorBoundary={resetErrorBoundary}
								/>
							)}
						>
							<Suspense
								fallback={<DetailsSkeleton />}
								key={
									details.mode === "multiple" ?
										`view=${details.view}&ids=${details.ids.join(",")}`
									:	`view=${details.view}&id=${details.id}`
								}
							>
								{details.mode === "single" && (
									<DetailViewComponent id={details.id} />
								)}
								{/* {details.mode === "multiple" && (
									<>
										{details.view === "work-order" && (
											<div>
												Multiple Work Orders View:{" "}
												{details.ids.join(", ")}
											</div>
										)}
									</>
								)} */}
							</Suspense>
						</ErrorBoundary>
					)}
				</QueryErrorResetBoundary>
			)}
		</ClientOnly>
	)
}
