import type { loader as customerLoader } from "@/routes/api+/customers.$customerId"
import type { SerializeFrom } from "@remix-run/node"
import { useSuspenseQuery } from "@tanstack/react-query"
import {
	EllipsisIcon,
	FileClock,
	Info,
	InfoIcon,
	PlusIcon,
	SearchIcon,
	Settings,
	TimerResetIcon,
	UsersIcon,
} from "lucide-react"

import type { Customer } from "@/server/schemas"
import { Button } from "@/components/ui/button"
import { Chip } from "@/components/ui/chip"
import {
	DetailsPage,
	DetailsPanel,
	DetailsResponsiveRenderer,
	DetailsScope,
	DetailsSectionHeader,
	DetailsSubtitle,
	DetailsTabs,
	DetailsTabsContent,
	DetailsTabsList,
	DetailsTabsTrigger,
	DetailsTitle,
	DetailsTopRightActions,
} from "@/components/ui/details-view"
import { Input, InputSlot } from "@/components/ui/input"
import { Separator } from "@/components/ui/separator"
import { Overline } from "@/components/ui/typography"
import type { DetailsViewComponent } from "@/components/shared/details/detail-view"
import { Notes } from "@/components/shared/details/notes"

import { ContactDetailsCard } from "../contact/details-card"
import { PurchaseOrderDetailsCard } from "../purchase-order/details-card"
import { WorkOrderDetailCard } from "../work-order/details-card"

export const CustomerDetailsView: DetailsViewComponent = (props) => {
	const { id: customerId } = props
	const { data: customer } = useSuspenseQuery({
		queryKey: ["customer", customerId],
		queryFn: async () => {
			const response = await fetch(`/api/customers/${customerId}`)

			if (!response.ok) {
				if (response.status === 404) {
					throw new Error(
						`Customer ${customerId} could not be found. Please check the ID and try again.`,
					)
				}
				throw new Error("Failed to fetch customer")
			}

			const { customer } = (await response.json()) as SerializeFrom<
				typeof customerLoader
			>

			if (!customer.success) {
				throw new Error(
					`Customer ${customerId} could not be found. Please check the ID and try again.`,
				)
			}
			return customer.value
		},
	})

	return (
		<DetailsResponsiveRenderer
			detailPage={<CustomerDetailsPage customer={customer} />}
			detailPanel={<CustomerDetailsPanel customer={customer} />}
		/>
	)
}

// ============================================================================
// Page
// ============================================================================

function CustomerDetailsPage({ customer }: { customer: Customer }) {
	return (
		<DetailsPage>
			<DetailsTopRightActions>
				<Button variant="ghost" size="icon">
					<EllipsisIcon className="size-4" />
				</Button>
			</DetailsTopRightActions>
			<DetailsScope feature="customer" />
			<section>
				<DetailsTitle>{customer.name}</DetailsTitle>
				<DetailsSubtitle>{customer.billingId}</DetailsSubtitle>
			</section>
			{/* TODO: Replace with real part tags */}
			<div className="flex gap-1">
				<Chip variant="primary">PRIMARY</Chip>
				<Chip variant="info">HARD TO FIND</Chip>
				<Chip variant="info">NON-BILLABLE</Chip>
			</div>
			<Separator className="my-2" />
			<div className="grid grid-cols-2 gap-4">
				<section>
					<div className="flex flex-col gap-3 p-2">
						<Notes
							notes={
								customer.notes ? [customer.notes] : undefined
							}
						/>
						<DetailsSectionHeader icon={Info}>
							Info
						</DetailsSectionHeader>
						<div className="flex flex-col gap-2">
							{customer.contacts.map((contact) => (
								<ContactDetailsCard
									key={contact.id}
									contact={contact}
								/>
							))}
						</div>
					</div>
					<div className="flex flex-col gap-3 p-2">
						<SettingsSection customer={customer} />
					</div>
				</section>
				<section>
					<HistorySection />
				</section>
			</div>
		</DetailsPage>
	)
}

// ============================================================================
// Panel
// ============================================================================

function CustomerDetailsPanel({ customer }: { customer: Customer }) {
	return (
		<DetailsPanel>
			<DetailsTopRightActions>
				<Button variant="ghost" size="icon">
					<EllipsisIcon className="size-4" />
				</Button>
			</DetailsTopRightActions>

			<DetailsScope feature="customer" />
			<section>
				<DetailsTitle>{customer.name}</DetailsTitle>
				<DetailsSubtitle>{customer.id}</DetailsSubtitle>
			</section>
			{/* TODO: Replace with real part tags */}
			<div className="flex gap-1">
				<Chip variant="primary">PRIMARY</Chip>
				<Chip variant="info">HARD TO FIND</Chip>
				<Chip variant="info">NON-BILLABLE</Chip>
			</div>
			<DetailsTabs defaultValue="info">
				<DetailsTabsList>
					<DetailsTabsTrigger value="info">
						<InfoIcon />
					</DetailsTabsTrigger>
					<DetailsTabsTrigger value="settings">
						<Settings />
					</DetailsTabsTrigger>
					<DetailsTabsTrigger value="history">
						<TimerResetIcon />
					</DetailsTabsTrigger>
				</DetailsTabsList>
				<DetailsTabsContent value="info">
					<div className="flex flex-col gap-3">
						<Notes
							notes={
								customer.notes ? [customer.notes] : undefined
							}
						/>
						{customer.contacts.map((contact) => (
							<ContactDetailsCard
								key={contact.id}
								contact={contact}
							/>
						))}
						<Button
							variant="outline"
							size="lg"
							className="w-full gap-2 border-dashed text-primary"
						>
							<PlusIcon className="size-4" />
							Add Contact
						</Button>
					</div>
				</DetailsTabsContent>
				<DetailsTabsContent value="settings">
					<SettingsSection customer={customer} />
				</DetailsTabsContent>
				<DetailsTabsContent value="history">
					<HistorySection />
				</DetailsTabsContent>
			</DetailsTabs>
		</DetailsPanel>
	)
}

interface SettingsSectionProps {
	customer: Customer
}

function SettingsSection({ customer }: SettingsSectionProps) {
	return (
		<div className="flex flex-col gap-3">
			<DetailsSectionHeader icon={Settings}>
				Settings
			</DetailsSectionHeader>
			<p className="text-lg font-bold">Email Notifications</p>
			<section>
				<Overline>Send to</Overline>
				<div className="flex justify-between">
					<p className="text-foreground-strong">Bill Smith</p>
					<p>billsmith@abcdtrucking.com</p>
				</div>
			</section>
			<div className="flex justify-between">
				<Overline>Work order completion?</Overline>
				<p className="capitalize text-foreground-strong">
					{customer.notifyWorkOrderComplete}
				</p>
			</div>
			<div className="flex justify-between">
				<Overline>Work order notes?</Overline>
				<p className="capitalize text-foreground-strong">
					{customer.notifyWorkOrderNotes}
				</p>
			</div>
			<Separator className="my-2" />
			<p className="text-xl font-bold">Rates</p>
			<div>
				<Overline>Terms</Overline>
				<p className="text-foreground-strong">
					{customer.billing?.termsCode}
				</p>
			</div>
			<div className="grid grid-cols-2 gap-3">
				<div>
					<Overline>Standard Price</Overline>
					<p className="text-foreground-strong">
						{customer.billing?.standardPriceCode}
					</p>
				</div>
				<div>
					<Overline>Flat Rate Price</Overline>
					<p className="text-foreground-strong">
						{customer.billing?.flatRatePriceCode}
					</p>
				</div>
				<div>
					<Overline>Labor Rate</Overline>
					<p className="text-foreground-strong">
						{customer.billing?.laborRate}
					</p>
				</div>
				<div>
					<Overline>Markup %</Overline>
					<p className="text-foreground-strong">
						{customer.billing?.laborMarkupPercent}
					</p>
				</div>
			</div>
			<div>
				<Overline>Fuel Markup %</Overline>
				<p className="text-foreground-strong">
					{customer.billing?.fuelMarkupPercent}
				</p>
			</div>
			<div className="flex justify-between">
				<Overline>Work order surcharge?</Overline>
				<p className="text-foreground-strong">
					{customer.billing?.useWorkOrderSurcharge ? "Yes" : "No"}
				</p>
			</div>
			<div className="flex justify-between">
				<Overline>Parts invoice surcharge?</Overline>
				<p className="text-foreground-strong">
					{customer.billing?.usePartsInvoiceSurcharge ? "Yes" : "No"}
				</p>
			</div>
			<Separator className="my-2" />
			<p className="text-xl font-bold">Accounting</p>
			<div>
				<Overline>Taxes</Overline>
				<p className="text-foreground-strong">{customer.tax1}</p>
			</div>
			<div className="flex justify-between">
				<Overline>Accounts receivable?</Overline>
				<p className="text-foreground-strong">
					{customer.accountsReceivable ? "Yes" : "No"}
				</p>
			</div>
			<div>
				<Overline>Department</Overline>
				<p className="text-foreground-strong">
					1000 - Fleet Maintenance
				</p>
			</div>
			<div>
				<Overline>Expense Category</Overline>
				<p className="text-foreground-strong">001 - Sales Department</p>
			</div>
			<div>
				<Overline>Location</Overline>
				<p className="text-foreground-strong">03 - North Region</p>
			</div>
		</div>
	)
}

function HistorySection() {
	return (
		<div className="flex flex-col gap-4">
			<DetailsSectionHeader icon={FileClock}>
				Customer History
			</DetailsSectionHeader>
			<Input placeholder="Search...">
				<InputSlot>
					<SearchIcon />
				</InputSlot>
			</Input>
			<PurchaseOrderDetailsCard />
			<WorkOrderDetailCard workOrder={{}} />
			<WorkOrderDetailCard workOrder={{}} />
		</div>
	)
}
