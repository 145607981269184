import type { FakePart } from "@/routes/_main+/resources+/parts/fake-parts"
import type { loader as partLoader } from "@/routes/api+/parts.$partId"
import type { SerializeFrom } from "@remix-run/node"
import { Link } from "@remix-run/react"
import { useSuspenseQuery } from "@tanstack/react-query"
import {
	ChevronsRight,
	EllipsisIcon,
	InfoIcon,
	Package2Icon,
	PlusIcon,
	SearchIcon,
	SettingsIcon,
	ShoppingCart,
	TimerResetIcon,
	WarehouseIcon,
} from "lucide-react"

import type { Location, Vendor } from "@/server/schemas"
import {
	Accordion,
	AccordionContent,
	AccordionItem,
	AccordionTrigger,
} from "@/components/ui/accordion"
import { Button } from "@/components/ui/button"
import { Chip } from "@/components/ui/chip"
import {
	DetailsPage,
	DetailsPanel,
	DetailsResponsiveRenderer,
	DetailsScope,
	DetailsSectionHeader,
	DetailsSubtitle,
	DetailsTabs,
	DetailsTabsContent,
	DetailsTabsList,
	DetailsTabsTrigger,
	DetailsTitle,
	DetailsTopRightActions,
} from "@/components/ui/details-view"
import { Input, InputSlot } from "@/components/ui/input"
import { Separator } from "@/components/ui/separator"
import { Skeleton } from "@/components/ui/skeleton"
import { Overline } from "@/components/ui/typography"
import type { DetailsViewComponent } from "@/components/shared/details/detail-view"
import { Notes } from "@/components/shared/details/notes"

import { LocationDetailsCard } from "../location/details-card"
import { PurchaseOrderDetailsCard } from "../purchase-order/details-card"
import { VendorDetailsCard } from "../vendor/details-card"
import { WorkOrderDetailCard } from "../work-order/details-card"
import { PartDetailsCard } from "./details-card"

export const PartDetailsView: DetailsViewComponent = (props) => {
	const { id: partId } = props
	const { data: part } = useSuspenseQuery({
		queryKey: ["part", partId],
		queryFn: async () => {
			const response = await fetch(`/api/parts/${partId}`)

			if (!response.ok) {
				if (response.status === 404) {
					throw new Error(
						`Part ${partId} could not be found. Please check the ID and try again.`,
					)
				}
				throw new Error("Failed to fetch location")
			}

			const { part } = (await response.json()) as SerializeFrom<
				typeof partLoader
			>

			return part
		},
	})

	return (
		<DetailsResponsiveRenderer
			detailPage={<PartDetailsPage part={part} />}
			detailPanel={<PartDetailsPanel part={part} />}
		/>
	)
}

function PartDetailsPage({ part }: { part: FakePart }) {
	return (
		<DetailsPage>
			<DetailsTopRightActions>
				<Button variant="ghost" size="icon">
					<EllipsisIcon className="size-4" />
				</Button>
			</DetailsTopRightActions>
			<DetailsScope feature="part" />
			<div className="flex items-center gap-2">
				<Skeleton className="h-[100px] w-[120px]" />
				<section>
					<DetailsTitle>{part.name}</DetailsTitle>
					<DetailsSubtitle>
						{part.MFGPartNo} | {part.MFG}
					</DetailsSubtitle>
					<div className="mt-2 flex gap-1">
						<Chip variant="primary">PRIMARY</Chip>
						<Chip variant="info">HARD TO FIND</Chip>
						<Chip variant="info">NON-BILLABLE</Chip>
					</div>
				</section>
			</div>

			<Separator />
			<div className="grid grid-cols-2 gap-4">
				<div className="flex flex-col gap-4">
					<InfoSection part={part} />
					<InventorySection part={part} />
				</div>
				<div className="flex flex-col gap-4">
					<SettingsSection part={part} />
					<PurchaseOrdersSection part={part} />
				</div>
			</div>
		</DetailsPage>
	)
}

function PartDetailsPanel({ part }: { part: FakePart }) {
	return (
		<DetailsPanel>
			<DetailsTopRightActions>
				<Button variant="ghost" size="icon">
					<EllipsisIcon className="size-4" />
				</Button>
			</DetailsTopRightActions>
			<DetailsScope feature="part" />

			<section>
				<DetailsTitle>{part.name}</DetailsTitle>
				<DetailsSubtitle>
					{part.MFGPartNo} | {part.MFG}
				</DetailsSubtitle>
			</section>
			{/* TODO: Replace with real part tags */}
			<div className="flex gap-1">
				<Chip variant="primary">PRIMARY</Chip>
				<Chip variant="info">HARD TO FIND</Chip>
				<Chip variant="info">NON-BILLABLE</Chip>
			</div>
			<div className="flex gap-2">
				<Skeleton className="flex-1 shrink-0" />
				<div className="flex min-w-[220px] flex-none flex-col gap-3 rounded-md border border-border p-3">
					<p className="w-full text-center font-bold">
						Downtown Warehouse 2
					</p>
					<div className="flex gap-2">
						<div className="flex flex-1 flex-col items-center gap-2">
							<Chip variant="success">IN STOCK</Chip>
							<p className="text-3xl font-bold">16</p>
						</div>
						<Separator
							orientation="vertical"
							className="flex-none"
						/>
						<div className="flex flex-1 flex-col gap-1 px-2">
							<div className="flex items-center justify-between">
								<Overline>Aisle</Overline>
								<p>AA</p>
							</div>
							<div className="flex items-center justify-between">
								<Overline>Shelf</Overline>
								<p>2</p>
							</div>
							<div className="flex items-center justify-between">
								<Overline>Bin</Overline>
								<p>6</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="flex justify-around p-2">
				<Button variant="link" className="m-0 p-0" size="none">
					<Link to="/">3 alt parts</Link>
				</Button>
				<Separator
					orientation="vertical"
					className="h-[20px] flex-none"
				/>
				<Button variant="link" className="m-0 p-0" size="none">
					<Link to="/">11 at other loc</Link>
				</Button>
				<Separator
					orientation="vertical"
					className="h-[20px] flex-none"
				/>
				<Button variant="link" className="m-0 p-0" size="none">
					<Link to="/">48 on order</Link>
				</Button>
			</div>
			<DetailsTabs defaultValue="info">
				<DetailsTabsList>
					<DetailsTabsTrigger value="info" className="grow">
						<InfoIcon />
					</DetailsTabsTrigger>
					<DetailsTabsTrigger value="settings" className="grow">
						<SettingsIcon />
					</DetailsTabsTrigger>
					<DetailsTabsTrigger value="inventory" className="grow">
						<WarehouseIcon />
					</DetailsTabsTrigger>
					<DetailsTabsTrigger value="orders" className="grow">
						<Package2Icon />
					</DetailsTabsTrigger>
					<DetailsTabsTrigger value="vendors" className="grow">
						<ShoppingCart />
					</DetailsTabsTrigger>
					<DetailsTabsTrigger value="history" className="grow">
						<TimerResetIcon />
					</DetailsTabsTrigger>
				</DetailsTabsList>
				<DetailsTabsContent value="info">
					<InfoSection part={part} />
				</DetailsTabsContent>
				<DetailsTabsContent value="settings">
					<SettingsSection part={part} />
				</DetailsTabsContent>
				<DetailsTabsContent value="inventory">
					<InventorySection part={part} />
				</DetailsTabsContent>
				<DetailsTabsContent value="orders">
					<PurchaseOrdersSection part={part} />
				</DetailsTabsContent>
				<DetailsTabsContent value="vendors">
					<VendorsSection part={part} />
				</DetailsTabsContent>
				<DetailsTabsContent value="history">
					<HistorySection part={part} />
				</DetailsTabsContent>
			</DetailsTabs>
		</DetailsPanel>
	)
}

function SettingsSection({ part }: { part: FakePart }) {
	return (
		<div className="flex flex-col gap-4">
			<DetailsSectionHeader>Settings</DetailsSectionHeader>
			<div className="grid grid-cols-2 gap-2">
				<section className="flex flex-col">
					<Overline>Critical Part</Overline>
					{part.criticalPart ? "Yes" : "No"}
				</section>
				<section className="flex flex-col">
					<Overline>Add to components</Overline>
					{part.addToComponents ? "Yes" : "No"}
				</section>
				<section className="flex flex-col">
					<Overline>Min</Overline>
					{part.min}
				</section>
				<section className="flex flex-col">
					<Overline>Max</Overline>
					{part.max}
				</section>
				<section className="flex flex-col">
					<Overline>Flat Rate Price</Overline>
					{part.flatRatePrice}
				</section>
				<section className="flex flex-col">
					<Overline>Taxable on purchases</Overline>
					{part.taxable ? "Yes" : "No"}
				</section>
			</div>
		</div>
	)
}

function PurchaseOrdersSection({ part }: { part: FakePart }) {
	return (
		<div className="flex flex-col gap-4">
			<DetailsSectionHeader>Purchase Orders</DetailsSectionHeader>
			<PurchaseOrderDetailsCard />
			<Button variant="ghost" className="w-full font-bold text-info-text">
				<PlusIcon className="size-4" /> Request Part
			</Button>
		</div>
	)
}

function InfoSection({ part }: { part: FakePart }) {
	return (
		<div className="flex flex-col gap-4">
			<Notes notes={part.notes ? [part.notes] : undefined} />
			<DetailsSectionHeader>Info</DetailsSectionHeader>
			<p className="text-foreground-strong">{part.description}</p>
			<div className="grid grid-cols-2 gap-2">
				<section className="flex flex-col">
					<Overline>Last cost</Overline>
					<p className="text-foreground-strong">
						${part.cost.toFixed(2)}
					</p>
				</section>
				<section className="flex flex-col">
					<Overline>Core Fee</Overline>
					<p className="text-foreground-strong">
						{part.coreFee.toFixed(2)}
					</p>
				</section>
				<section className="flex flex-col">
					<Overline>Bar Code</Overline>
					<p className="text-foreground-strong">{part.barCode}</p>
				</section>
				<section className="flex flex-col">
					<Overline>MFG Number</Overline>
					<p className="text-foreground-strong">{part.MFGPartNo}</p>
				</section>
				<section className="flex flex-col">
					<Overline>Unit of measure</Overline>
					<p className="capitalize text-foreground-strong">
						{part.unitOfMeasure}
					</p>
				</section>
			</div>
			<Accordion type="multiple">
				<AccordionItem value="alternates">
					<AccordionTrigger className="text-lg font-bold">
						Alternate Parts (3)
					</AccordionTrigger>
					<AccordionContent className="px-0">
						<div className="flex flex-col gap-2">
							<PartDetailsCard />
							<PartDetailsCard />
							<PartDetailsCard />
						</div>
					</AccordionContent>
				</AccordionItem>
				<AccordionItem value="specs">
					<AccordionTrigger className="text-lg font-bold">
						Specifications
					</AccordionTrigger>
					<AccordionContent>
						Custom meta/specs goes here
					</AccordionContent>
				</AccordionItem>
				<AccordionItem value="attachments">
					<AccordionTrigger className="text-lg font-bold">
						Attachments (1)
					</AccordionTrigger>
					<AccordionContent>
						<Button variant="link" size="none" className="p-0">
							<Link to="/">amber_bulb_part_guide.pdf</Link>{" "}
						</Button>
					</AccordionContent>
				</AccordionItem>
			</Accordion>
		</div>
	)
}

function InventorySection({ part }: { part: FakePart }) {
	const fakeLocation: Location = {
		id: 0,
		name: "Main Shop",
		street1: "123 Main St",
		street2: null,
		city: "Seattle",
		state: "WA",
		zip: "98126",
		contactName: "Ed Orgeron",
		contactEmail: "ed@orgeron.com",
		contactPhone: "555-555-5555",
		workstationName: "Main Shop",
		accountCode: "002",
		notes: null,
		isActive: true,
	} //TODO: Replace with real locations where this part is stored

	return (
		<div className="flex flex-col gap-4">
			<DetailsSectionHeader>Inventory</DetailsSectionHeader>
			{/* TODO: Map over locations where this part is stored */}
			<LocationDetailsCard location={fakeLocation} />
			<Button variant="ghost" className="w-full font-bold text-info-text">
				<ChevronsRight className="size-4" />
				Transfer part in
			</Button>
		</div>
	)
}

function VendorsSection({ part }: { part: FakePart }) {
	const fakeVendor: Vendor = {
		id: 0,
		name: "Bertand's Bright Stuff",
		street1: "123 Main St",
		street2: null,
		city: "Salt Lake City",
		state: "UT",
		zip: "32321",
		accountCode: "002",
		notes: null,
		isActive: true,
		performanceRank: 1,
		email: "bill.smythe@gbt.com",
		phone: "801-555-1212",
		termsCode: null,
		contacts: [
			{
				id: 0,
				name: "Bill Smythe",
				email: "bill.smythe@gbt.com",
				phone: "801-555-1212",
				vendorId: 0,
				isRepresentative: true,
			},
		],
	}

	return (
		<div className="flex flex-col gap-3">
			<DetailsSectionHeader>Vendors</DetailsSectionHeader>
			<VendorDetailsCard vendor={fakeVendor} />
		</div>
	)
}

function HistorySection({ part }: { part: FakePart }) {
	return (
		<div className="flex flex-col gap-3">
			<DetailsSectionHeader>Part Usage History</DetailsSectionHeader>
			<Input placeholder="Search...">
				<InputSlot>
					<SearchIcon />
				</InputSlot>
			</Input>
			<WorkOrderDetailCard workOrder={{}} />
			<WorkOrderDetailCard workOrder={{}} />
		</div>
	)
}
