import type { DetailsViewComponent } from "@/components/shared/details/detail-view"
import { CustomerDetailsView } from "@/components/features/customer/details-view"
import { EmployeeDetailsView } from "@/components/features/employee/detail-view"
import { EquipmentDetailsView } from "@/components/features/equipment/details-view"
import { LocationDetailsView } from "@/components/features/location/details-view"
import { PartDetailsView } from "@/components/features/part/details-view"
import { VendorDetailsView } from "@/components/features/vendor/details-view"

type DetailsViewConfigShape = Record<string, DetailsViewComponent>

export const detailsViewConfig = {
	customer: CustomerDetailsView,
	employee: EmployeeDetailsView,
	equipment: EquipmentDetailsView,
	location: LocationDetailsView,
	part: PartDetailsView,
	vendor: VendorDetailsView,

	error: {} as DetailsViewComponent,
} as const satisfies DetailsViewConfigShape

export type DetailsView = keyof typeof detailsViewConfig

// Asserts that a string is a valid DetailsView
export function isDetailsView(view: string): view is DetailsView {
	return Object.keys(detailsViewConfig).includes(view as DetailsView)
}
