import { EllipsisIcon, MailIcon, PhoneIcon } from "lucide-react"

import type { CustomerContact } from "@/server/schemas"
import { Button } from "@/components/ui/button"
import { Chip } from "@/components/ui/chip"
import {
	DetailsCard,
	DetailsCardInfoLine,
	DetailsCardOverline,
	DetailsCardSubtitle,
	DetailsCardTitle,
} from "@/components/ui/details-card"

interface ContactDetailsCardProps {
	contact: CustomerContact
}
export function ContactDetailsCard({ contact }: ContactDetailsCardProps) {
	return (
		<DetailsCard>
			<DetailsCardOverline icon={PhoneIcon}>Contact</DetailsCardOverline>
			<Button
				variant="ghost"
				size="icon"
				className="absolute right-2 top-2"
			>
				<EllipsisIcon className="size-4" />
			</Button>

			<section>
				<DetailsCardTitle>{contact.name}</DetailsCardTitle>
				<DetailsCardSubtitle>{contact.title}</DetailsCardSubtitle>
			</section>

			<Chip variant="info" className="w-fit uppercase">
				Billing Contact
			</Chip>

			<section>
				<DetailsCardInfoLine icon={MailIcon}>
					{contact.email ?? (
						<p className="select-none text-foreground-weaker">
							N/A
						</p>
					)}
				</DetailsCardInfoLine>
				<DetailsCardInfoLine icon={PhoneIcon}>
					{contact.phone ?? (
						<p className="select-none text-foreground-weaker">
							(xxx) xxx-xxxx
						</p>
					)}
				</DetailsCardInfoLine>
			</section>
		</DetailsCard>
	)
}
