import type { loader as vendorLoader } from "@/routes/api+/vendors.$vendorId"
import type { SerializeFrom } from "@remix-run/node"
import { Link } from "@remix-run/react"
import { useSuspenseQuery } from "@tanstack/react-query"
import {
	EllipsisIcon,
	FileClock,
	GlobeIcon,
	Info,
	InfoIcon,
	MailIcon,
	PhoneIcon,
	PlusIcon,
	SearchIcon,
	Settings,
	SettingsIcon,
	TimerResetIcon,
} from "lucide-react"

import type { Vendor } from "@/server/schemas/vendor"
import {
	Accordion,
	AccordionContent,
	AccordionItem,
	AccordionTrigger,
} from "@/components/ui/accordion"
import { Button } from "@/components/ui/button"
import { Chip } from "@/components/ui/chip"
import {
	DetailsPage,
	DetailsPanel,
	DetailsResponsiveRenderer,
	DetailsScope,
	DetailsSectionHeader,
	DetailsSubtitle,
	DetailsTabs,
	DetailsTabsContent,
	DetailsTabsList,
	DetailsTabsTrigger,
	DetailsTitle,
	DetailsTopRightActions,
} from "@/components/ui/details-view"
import { Input, InputSlot } from "@/components/ui/input"
import { Separator } from "@/components/ui/separator"
import { Overline } from "@/components/ui/typography"
import type { DetailsViewComponent } from "@/components/shared/details/detail-view"
import { Notes } from "@/components/shared/details/notes"

import { PurchaseOrderDetailsCard } from "../purchase-order/details-card"

export const VendorDetailsView: DetailsViewComponent = (props) => {
	const { id: vendorId } = props
	const { data: vendor } = useSuspenseQuery({
		queryKey: ["vendor", vendorId],
		queryFn: async () => {
			const response = await fetch(`/api/vendors/${vendorId}`)

			if (!response.ok) {
				if (response.status === 404) {
					throw new Error(
						`Vendor ${vendorId} could not be found. Please check the ID and try again.`,
					)
				}
				throw new Error("Failed to fetch vendor")
			}

			const { vendor } = (await response.json()) as SerializeFrom<
				typeof vendorLoader
			>

			return vendor
		},
	})

	return (
		<DetailsResponsiveRenderer
			detailPage={<VendorDetailsPage vendor={vendor} />}
			detailPanel={<VendorDetailsPanel vendor={vendor} />}
		/>
	)
}

//===========================================================================
// Page
//===========================================================================

function VendorDetailsPage({ vendor }: { vendor: Vendor }) {
	return (
		<DetailsPage>
			<DetailsTopRightActions>
				<Button variant="ghost" size="icon">
					<EllipsisIcon className="size-4" />
				</Button>
			</DetailsTopRightActions>
			<DetailsScope feature="vendor" />
			<div className="flex gap-2 py-1">
				<section className="grow">
					<DetailsTitle>{vendor.name}</DetailsTitle>
					<DetailsSubtitle>
						{vendor.city}, {vendor.state}
					</DetailsSubtitle>
					<TagsSection vendor={vendor} />
				</section>
				<section className="shrink-0">
					<LinksSection vendor={vendor} />
				</section>
			</div>

			<Separator />

			<div className="grid grid-cols-2 gap-4 py-2">
				<div className="flex flex-col gap-4">
					<Notes notes={vendor.notes ? [vendor.notes] : undefined} />
					<InfoSection vendor={vendor} />
					<ContactsSection vendor={vendor} />
					<AttachmentsSection vendor={vendor} />
					<SettingsSection vendor={vendor} />
				</div>
				<HistorySection />
			</div>
		</DetailsPage>
	)
}

function VendorDetailsPanel({ vendor }: { vendor: Vendor }) {
	return (
		<DetailsPanel>
			<DetailsTopRightActions>
				<Button variant="ghost" size="icon">
					<EllipsisIcon className="size-4" />
				</Button>
			</DetailsTopRightActions>

			<DetailsScope feature="vendor" />
			<section>
				<DetailsTitle>{vendor.name}</DetailsTitle>
				<DetailsSubtitle>
					{vendor.city}, {vendor.state}
				</DetailsSubtitle>
				<TagsSection vendor={vendor} />
				<LinksSection vendor={vendor} />
			</section>
			<DetailsTabs defaultValue="info">
				<DetailsTabsList>
					<DetailsTabsTrigger value="info" className="grow">
						<InfoIcon />
					</DetailsTabsTrigger>
					<DetailsTabsTrigger value="settings" className="grow">
						<Settings />
					</DetailsTabsTrigger>
					<DetailsTabsTrigger value="history" className="grow">
						<TimerResetIcon />
					</DetailsTabsTrigger>
				</DetailsTabsList>
				<DetailsTabsContent value="info">
					<div className="flex flex-col gap-3">
						<Notes
							notes={vendor.notes ? [vendor.notes] : undefined}
						/>
						<InfoSection vendor={vendor} />
						<ContactsSection vendor={vendor} />
						<AttachmentsSection vendor={vendor} />
					</div>
				</DetailsTabsContent>
				<DetailsTabsContent value="settings">
					<SettingsSection vendor={vendor} />
				</DetailsTabsContent>
				<DetailsTabsContent value="history">
					<HistorySection />
				</DetailsTabsContent>
			</DetailsTabs>
		</DetailsPanel>
	)
}

function HistorySection() {
	return (
		<div className="flex flex-col gap-4">
			<DetailsSectionHeader icon={FileClock}>
				Vendor History
			</DetailsSectionHeader>
			<Input placeholder="Search...">
				<InputSlot>
					<SearchIcon />
				</InputSlot>
			</Input>
			<PurchaseOrderDetailsCard />
		</div>
	)
}

function InfoSection({ vendor }: { vendor: Vendor }) {
	return (
		<div className="flex flex-col gap-4">
			<DetailsSectionHeader icon={Info}>Info</DetailsSectionHeader>

			<section className="flex flex-col">
				<Overline>Address</Overline>
				<span className="text-foreground-strong">
					{vendor.street1}
					{vendor.street2 && ", " + vendor.street2}
				</span>
				<span className="text-foreground-strong">
					{vendor.city}, {vendor.state} {vendor.zip}
				</span>
			</section>
			<section className="flex flex-col">
				<Overline>Return Address</Overline>
				<span className="text-foreground-strong">
					{vendor.street1}
					{vendor.street2 && ", " + vendor.street2}
				</span>
				<span className="text-foreground-strong">
					{vendor.city}, {vendor.state} {vendor.zip}
				</span>
			</section>
		</div>
	)
}

function ContactsSection({ vendor }: { vendor: Vendor }) {
	return (
		<>
			<div className="flex items-center gap-2">
				<p className="text-lg font-bold">Contacts</p>
			</div>
			<div className="flex flex-wrap gap-2">
				{vendor.contacts.length > 0 ?
					vendor.contacts.map((contact) => (
						//<ContactDetailsCard key={contact.id} contact={contact} />
						<div key={contact.id}> Contact Details Card </div>
					))
				:	<p className="w-full p-4 text-center text-foreground-weak">
						No Contacts
					</p>
				}
			</div>
			<Button
				variant="outline"
				className="w-full gap-2 border-dashed border-border text-primary shadow-transparent"
			>
				<PlusIcon className="size-4" />
				Add Contact
			</Button>
		</>
	)
}

function AttachmentsSection({ vendor }: { vendor: Vendor }) {
	//TODO: Replace with real attachments
	return (
		<Accordion type="multiple">
			<AccordionItem value="attachments">
				<AccordionTrigger className="text-lg font-bold">
					Attachments (1)
				</AccordionTrigger>
				<AccordionContent>
					<Button variant="link" className="m-0 p-0">
						<Link to="/">bobs_parts_catalog_2024.pdf</Link>
					</Button>
				</AccordionContent>
			</AccordionItem>
		</Accordion>
	)
}

function SettingsSection({ vendor }: { vendor: Vendor }) {
	return (
		<div className="flex flex-col gap-4">
			<DetailsSectionHeader icon={Settings}>
				Settings
			</DetailsSectionHeader>
			<div className="grid grid-cols-2 gap-4">
				<section className="flex flex-col">
					<Overline>Active</Overline>
					<span className="text-foreground-strong">
						{vendor.isActive ? "Yes" : "No"}
					</span>
				</section>
				<section className="flex flex-col">
					<Overline>Performance Rank</Overline>
					<span className="text-foreground-strong">
						{vendor.performanceRank}
					</span>
				</section>
			</div>
			<p className="text-xl font-bold text-foreground-weak">Accounting</p>
			<section className="flex flex-col">
				<Overline>Taxes</Overline>
				<span className="text-foreground-strong">
					Utah Sales Tax
					{/* TODO: Replace with real taxes */}
				</span>
			</section>
			<section className="flex flex-col">
				<Overline>Terms</Overline>
				<span className="text-foreground-strong">
					Net 30
					{/* TODO: Replace with real terms */}
				</span>
			</section>
			<section className="flex flex-col">
				<Overline>Department</Overline>
				<span className="text-foreground-strong">
					1000 - Fleet Maintenance
					{/* TODO: Replace with real department */}
				</span>
			</section>
			<section className="flex flex-col">
				<Overline>Expense Category</Overline>
				<span className="text-foreground-strong">
					001 - Sales Department
					{/* TODO: Replace with real expense category */}
				</span>
			</section>
			<section className="flex flex-col">
				<Overline>Location</Overline>
				<span className="text-foreground-strong">
					03 - North Region
					{/* TODO: Replace with real location */}
				</span>
			</section>
		</div>
	)
}

function TagsSection({ vendor }: { vendor: Vendor }) {
	//TODO: Replace with real tags
	return (
		<div className="flex flex-wrap items-center gap-2 py-3">
			<Chip variant="primary">PREFERRED</Chip>
			<Chip variant="success">NET30</Chip>
			<Chip variant="info">FAST SHIPPING</Chip>
			<Chip variant="info">ONLINE</Chip>
			<Chip variant="info">REGIONAL</Chip>
		</div>
	)
}

function LinksSection({ vendor }: { vendor: Vendor }) {
	return (
		<>
			<div className="flex items-center">
				<GlobeIcon className="text-foreground-weak" />
				<Button variant="link" size="none">
					<Link to="/">www.addwebsite.com</Link>
				</Button>
			</div>
			<div className="flex items-center">
				<MailIcon className="text-foreground-weak" />
				<Button variant="link" size="none">
					<Link to="/">order@website.com</Link>
				</Button>
			</div>
			<div className="flex items-center">
				<PhoneIcon className="text-foreground-weak" />
				<p className="px-2 text-foreground-strong">(801) 555-5432</p>
			</div>
		</>
	)
}
