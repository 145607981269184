import { useLocation } from "@remix-run/react"

import { featureConfig } from "@/config/features"
import { getPageFromPathname } from "@/config/pages"
import { Page } from "@/components/ui/page"

export function NothingSelected() {
	const { pathname } = useLocation()
	const page = getPageFromPathname(pathname)

	const feature = page?.feature

	return (
		<Page className="hidden size-full place-items-center text-foreground-weak md:grid">
			{feature ?
				`No ${featureConfig[feature].labels.singular} selected.`
			:	"Nothing selected"}
		</Page>
	)
}
