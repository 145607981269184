import React from "react"
import type { HTMLAttributes } from "react"

import { cn } from "@/lib/utils/classnames"

function Skeleton({ className, ...props }: HTMLAttributes<HTMLDivElement>) {
	return (
		<div
			className={cn(
				"animate-pulse rounded-md bg-background-weaker",
				className,
			)}
			{...props}
		/>
	)
}

export { Skeleton }
