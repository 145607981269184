import {
	EllipsisIcon,
	Hammer,
	MailIcon,
	PhoneIcon,
	ShoppingCart,
	TimerIcon,
	User,
	UserIcon,
	Wrench,
	WrenchIcon,
} from "lucide-react"

import { Avatar, AvatarFallback } from "@/components/ui/avatar"
import { Button } from "@/components/ui/button"
import { Card } from "@/components/ui/card"
import { Chip } from "@/components/ui/chip"
import {
	DetailsCard,
	DetailsCardInfoLine,
	DetailsCardOverline,
	DetailsCardSubtitle,
	DetailsCardTitle,
} from "@/components/ui/details-card"

interface WorkOrderDetailCardProps {
	workOrder: unknown // TODO: type WorkOrder
}
export function WorkOrderDetailCard({ workOrder }: WorkOrderDetailCardProps) {
	return (
		<DetailsCard statusline="info">
			<DetailsCardOverline icon={WrenchIcon}>
				Work Order
			</DetailsCardOverline>
			<Button
				variant="ghost"
				size="icon"
				className="absolute right-1 top-1"
			>
				<EllipsisIcon className="size-4" />
			</Button>

			<section>
				<DetailsCardTitle>2015 Freightliner Cascadia</DetailsCardTitle>
				<DetailsCardSubtitle>
					1FK4KSLFSQACU23J02 | DLK3482
				</DetailsCardSubtitle>
			</section>

			{/* TODO: Replace with real vendor tags */}
			<div className="flex flex-wrap items-center gap-2">
				<Chip variant="primary">PM</Chip>
				<Chip variant="success">BAY 2</Chip>
				<Chip variant="error">WAITING FOR PARTS</Chip>
			</div>

			<section>
				<DetailsCardInfoLine icon={Hammer}>
					Transmission fluid check and change
				</DetailsCardInfoLine>
				<DetailsCardInfoLine icon={User}>
					John Anderson
				</DetailsCardInfoLine>
				<Button
					variant="outline"
					className="mt-2 gap-1 border-success-border/50 bg-success/30 text-lg font-bold"
					size="sm"
				>
					<TimerIcon className="text-success" />
					1h 34m
					<Avatar className="size-6">
						<AvatarFallback className="text-sm">JA</AvatarFallback>
					</Avatar>
				</Button>
			</section>
		</DetailsCard>
	)
}
