import type { loader as locationLoader } from "@/routes/api+/locations.$locationId"
import type { SerializeFrom } from "@remix-run/node"
import { useSuspenseQuery } from "@tanstack/react-query"
import {
	EllipsisIcon,
	InfoIcon,
	SettingsIcon,
	TimerResetIcon,
	UserIcon,
	UsersIcon,
	WarehouseIcon,
} from "lucide-react"

import type { CustomerContact, Location } from "@/server/schemas"
import { Button } from "@/components/ui/button"
import { Card } from "@/components/ui/card"
import { Chip } from "@/components/ui/chip"
import {
	DetailsPage,
	DetailsPanel,
	DetailsResponsiveRenderer,
	DetailsScope,
	DetailsSectionHeader,
	DetailsSubtitle,
	DetailsTabs,
	DetailsTabsContent,
	DetailsTabsList,
	DetailsTabsTrigger,
	DetailsTitle,
	DetailsTopRightActions,
} from "@/components/ui/details-view"
import { Separator } from "@/components/ui/separator"
import { Skeleton } from "@/components/ui/skeleton"
import { Overline } from "@/components/ui/typography"
import type { DetailsViewComponent } from "@/components/shared/details/detail-view"
import { Notes } from "@/components/shared/details/notes"

import { ContactDetailsCard } from "../contact/details-card"
import { LocationMap } from "./map.client"

export const LocationDetailsView: DetailsViewComponent = (props) => {
	const { id: locationId } = props
	const { data: location } = useSuspenseQuery({
		queryKey: ["location", locationId],
		queryFn: async () => {
			const response = await fetch(`/api/locations/${locationId}`)

			if (!response.ok) {
				if (response.status === 404) {
					throw new Error(
						`Location ${locationId} could not be found. Please check the ID and try again.`,
					)
				}
				throw new Error("Failed to fetch location")
			}

			const { location } = (await response.json()) as SerializeFrom<
				typeof locationLoader
			>

			return location.value
		},
	})

	return (
		<DetailsResponsiveRenderer
			detailPage={<LocationDetailsPage location={location} />}
			detailPanel={<LocationDetailsPanel location={location} />}
		/>
	)
}

function LocationDetailsPage({ location }: { location: Location }) {
	const fakeContact: CustomerContact = {
		//TODO: standardize contacts
		id: 0,
		name: location.contactName ?? "N/A",
		email: location.contactEmail ?? "test@test.com",
		customerId: 0,
		title: "",
		phone: location.contactPhone ?? "(555) 123-4567",
		role: "",
	}

	return (
		<DetailsPage>
			<DetailsTopRightActions>
				<Button variant="ghost" size="icon">
					<EllipsisIcon className="size-4" />
				</Button>
			</DetailsTopRightActions>
			<DetailsScope feature="location" />
			<section>
				<DetailsTitle>{location.name}</DetailsTitle>
				<DetailsSubtitle>
					{location.street1} {location.street2} {location.city},{" "}
					{location.state}
				</DetailsSubtitle>
			</section>
			<Chip
				variant={location.isActive ? "success" : "error"}
				className="w-fit"
			>
				{location.isActive ? "Active" : "Inactive"}
			</Chip>
			<div className="flex gap-4">
				<LocationMap location={location} />

				<div className="flex flex-col gap-2 px-3">
					<Overline>Contact Name</Overline>
					<p>{location.contactName ?? "N/A"}</p>
					<Overline>Contact Phone</Overline>
					<p>{location.contactPhone ?? "N/A"}</p>
				</div>
			</div>
			<DetailsTabs defaultValue="info">
				<DetailsTabsList>
					<DetailsTabsTrigger value="info">
						<InfoIcon />
						Information
					</DetailsTabsTrigger>
					<DetailsTabsTrigger value="history">
						<TimerResetIcon />
						History
					</DetailsTabsTrigger>
				</DetailsTabsList>
				<DetailsTabsContent value="info">
					<div className="grid grid-cols-2 gap-x-4">
						<div className="flex flex-col gap-4">
							<Notes
								notes={
									location.notes ?
										[location.notes]
									:	undefined
								}
							/>
							<DetailsSectionHeader>
								Contacts
							</DetailsSectionHeader>
							<ContactDetailsCard contact={fakeContact} />
						</div>
						<div className="flex flex-col gap-4">
							<DetailsSectionHeader>
								Settings
							</DetailsSectionHeader>
						</div>
					</div>
				</DetailsTabsContent>
				<DetailsTabsContent value="history">
					<LocationHistoryPanel />
				</DetailsTabsContent>
			</DetailsTabs>
		</DetailsPage>
	)
}

function LocationDetailsPanel({ location }: { location: Location }) {
	const fakeContact: CustomerContact = {
		//TODO: standardize contacts
		id: 0,
		name: location.contactName ?? "N/A",
		email: location.contactEmail ?? "test@test.com",
		customerId: 0,
		title: "",
		phone: location.contactPhone ?? "(555) 123-4567",
		role: "",
	}

	return (
		<DetailsPanel>
			<DetailsTopRightActions>
				<Button variant="ghost" size="icon">
					<EllipsisIcon className="size-4" />
				</Button>
			</DetailsTopRightActions>

			<DetailsScope feature="location" />
			<section>
				<DetailsTitle>{location.name}</DetailsTitle>
				<DetailsSubtitle>
					{location.street1} {location.street2}
				</DetailsSubtitle>
			</section>
			<Chip
				variant={location.isActive ? "success" : "error"}
				className="w-fit"
			>
				{location.isActive ? "Active" : "Inactive"}
			</Chip>
			<div className="flex gap-4">
				<LocationMap location={location} />

				<div className="flex flex-col gap-2 px-3">
					<Overline>Contact Name</Overline>
					<p>{location.contactName ?? "N/A"}</p>
					<Overline>Contact Phone</Overline>
					<p>{location.contactPhone ?? "N/A"}</p>
				</div>
			</div>
			<DetailsTabs defaultValue="info">
				<DetailsTabsList>
					<DetailsTabsTrigger value="info" className="grow">
						<InfoIcon />
					</DetailsTabsTrigger>
					<DetailsTabsTrigger value="contacts" className="grow">
						<UsersIcon />
					</DetailsTabsTrigger>
					<DetailsTabsTrigger value="parts" className="grow">
						<WarehouseIcon />
					</DetailsTabsTrigger>
					<DetailsTabsTrigger value="history" className="grow">
						<TimerResetIcon />
					</DetailsTabsTrigger>
				</DetailsTabsList>
				<DetailsTabsContent value="info">
					<Notes
						notes={location.notes ? [location.notes] : undefined}
					/>
					<div className="flex flex-col gap-2 p-3">
						<DetailsSectionHeader>Details</DetailsSectionHeader>
						<section>
							<Overline>Warehouse ID</Overline>
							<p className="font-semibold">
								MAIN {/* TODO: Replace with real ID */}
							</p>
						</section>
						<section>
							<Overline>Warehouse Name</Overline>
							<p className="font-semibold">
								{location.workstationName}
							</p>
						</section>
						<section>
							<Overline>Address</Overline>
							<p className="font-semibold">
								{location.street1} {location.street2}
							</p>
							<p className="font-semibold">
								{location.city}, {location.state} {location.zip}
							</p>
						</section>
					</div>
					<Separator />
					<div className="flex flex-col gap-2 p-3">
						<DetailsSectionHeader>
							Account Codes
						</DetailsSectionHeader>
						<section>
							<Overline>Seg Account Code 1</Overline>
							<p className="font-semibold">
								{location.accountCode}
							</p>
						</section>
					</div>
				</DetailsTabsContent>
				<DetailsTabsContent value="contacts">
					<div className="flex flex-col gap-2">
						<ContactDetailsCard contact={fakeContact} />
					</div>
				</DetailsTabsContent>
				<DetailsTabsContent value="parts">
					<div className="flex flex-col gap-2">Parts</div>
				</DetailsTabsContent>
				<DetailsTabsContent value="history">
					<LocationHistoryPanel />
				</DetailsTabsContent>
			</DetailsTabs>
		</DetailsPanel>
	)
}

function LocationHistoryPanel() {
	return (
		<div className="flex flex-col gap-2">
			<Overline>Location History</Overline>
			<Card className="relative flex flex-col gap-2 p-3">
				<Skeleton className="h-4 w-3/5" />
				<Skeleton className="h-4 w-1/2" />
				<Skeleton className="h-4 w-2/3" />
				<p className="absolute right-0 top-0 p-2 text-sm">10/22/2023</p>
			</Card>
			<Card className="relative flex flex-col gap-2 p-3">
				<Skeleton className="h-4 w-1/2" />
				<Skeleton className="h-4 w-2/3" />
				<Skeleton className="h-4 w-2/3" />
				<p className="absolute right-0 top-0 p-2 text-sm">08/01/2022</p>
			</Card>
		</div>
	)
}
