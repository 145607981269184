import { forwardRef } from "react"
import type { LucideIcon } from "lucide-react"

import { cn } from "@/lib/utils/classnames"
import { Card } from "@/components/ui/card"
import { typographyVariants } from "@/components/ui/typography"

import type { StatusLineType } from "./queue"

const DetailsCard = forwardRef<
	React.ElementRef<typeof Card>,
	React.ComponentPropsWithoutRef<typeof Card> & {
		statusline?: StatusLineType
	}
>(({ className, ...props }, ref) => (
	<Card
		ref={ref}
		className={cn(
			"relative flex flex-col gap-2 rounded-[5px] px-3 py-2",
			props.statusline && "pl-5",
			className,
		)}
		{...props}
	>
		{props.statusline && (
			<div
				className={cn(
					"absolute inset-y-[3px] left-[3px] w-1 rounded-full",
					props.statusline === "success" && "bg-success",
					props.statusline === "warning" && "bg-warning",
					props.statusline === "error" && "bg-error",
					props.statusline === "info" && "bg-info",
					props.statusline === "primary" && "bg-primary",
				)}
			/>
		)}
		{props.children}
	</Card>
))
DetailsCard.displayName = "DetailsCard"

interface DetailsCardOverlineProps {
	icon?: LucideIcon
	children?: React.ReactNode
}
export const DetailsCardOverline = ({
	icon: Icon,
	children,
}: DetailsCardOverlineProps) => (
	<div
		className={cn(
			"flex items-center gap-2",
			typographyVariants({ variant: "overline" }),
		)}
	>
		{Icon && <Icon className="size-4" />}
		{children}
	</div>
)

export const DetailsCardTitle = ({
	children,
}: {
	children?: React.ReactNode
}) => <div className="text-lg font-bold text-foreground-strong">{children}</div>

export const DetailsCardSubtitle = ({
	children,
}: {
	children?: React.ReactNode
}) => <div className="text-sm">{children}</div>

interface DetailsCardInfoLineProps {
	icon?: LucideIcon
	children?: React.ReactNode
}
export const DetailsCardInfoLine = ({
	icon: Icon,
	children,
}: DetailsCardInfoLineProps) => (
	<div className="flex items-center gap-2">
		{Icon && <Icon className="size-4 text-foreground-weak" />}
		<div className="text-base">{children}</div>
	</div>
)

export { DetailsCard }
