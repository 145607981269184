import type { ReactNode } from "react"
import { forwardRef } from "react"
import { useLocation } from "@remix-run/react"
import { ChevronRight, XIcon } from "lucide-react"
import type { LucideIcon } from "lucide-react"

import type { Feature } from "@/config/features"
import { featureConfig } from "@/config/features"
import { getPageFromPathname } from "@/config/pages"
import { cn } from "@/lib/utils/classnames"

import { useDetailsState } from "../shared/details/use-details-state"
import { useListView } from "../shared/layout"
import { Button } from "./button"
import { Separator } from "./separator"
import { Skeleton } from "./skeleton"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "./tabs"

interface DetailsResponsiveRendererProps {
	detailPage: ReactNode
	detailPanel: ReactNode
}

/**
 * @description 		A component that displays a detail-page or a detail-panel based on the container size.
 * @param detailPage 	The page to display in larger containers.
 * @param detailPanel 	The panel to display in smaller containers.
 */
export function DetailsResponsiveRenderer({
	detailPage,
	detailPanel,
}: DetailsResponsiveRendererProps) {
	return (
		<div className="size-full @container">
			<div className="hidden @lg:block">{detailPage}</div>
			<div className="@lg:hidden">{detailPanel}</div>
		</div>
	)
}

const DetailsTitle = forwardRef<
	HTMLDivElement,
	React.HTMLProps<HTMLDivElement>
>(({ children, className, ...props }, ref) => (
	<div ref={ref} {...props} className={cn("text-xl font-bold", className)}>
		{children}
	</div>
))
DetailsTitle.displayName = "DetailsTitle"

const DetailsSubtitle = forwardRef<
	HTMLDivElement,
	React.HTMLProps<HTMLDivElement>
>(({ children, className, ...props }, ref) => (
	<div
		ref={ref}
		{...props}
		className={cn(
			"text-sm font-normal uppercase text-foreground",
			className,
		)}
	>
		{children}
	</div>
))
DetailsSubtitle.displayName = "DetailSubtitle"

interface DetailsScopeProps {
	feature: Feature
}
function DetailsScope({ feature }: DetailsScopeProps) {
	const { pathname } = useLocation()
	const page = getPageFromPathname(pathname)
	if (!page) return null

	const CurrentPageIcon = featureConfig[page.feature].icon
	const NestedPageIcon = featureConfig[feature].icon

	return (
		<div className="flex items-center gap-1">
			<CurrentPageIcon className="size-4 text-foreground-weak" />
			<span className="text-sm font-medium uppercase tracking-wider text-primary dark:text-primary-text">
				{featureConfig[page.feature].labels.singular}
			</span>
			{page.feature !== feature && (
				<>
					<ChevronRight className="size-4 text-foreground-weak" />
					<NestedPageIcon className="size-4 text-foreground-weak" />
					<span className="text-sm font-medium uppercase tracking-wider text-primary">
						{featureConfig[feature].labels.singular}
					</span>
				</>
			)}
		</div>
	)
}

const DetailsTopRightActions = forwardRef<
	HTMLDivElement,
	React.HTMLProps<HTMLDivElement>
>(({ children, className, ...props }, ref) => {
	const { view } = useListView()

	return (
		<div
			ref={ref}
			{...props}
			className={cn(
				"absolute right-2 top-2 flex items-center justify-end gap-2",
				className,
			)}
		>
			{children}
			{view !== "queue" ?
				<DetailsPanelCloseButton />
			:	<DetailsPanelCloseButton className="md:hidden" />}
		</div>
	)
})
DetailsTopRightActions.displayName = "DetailsTopRightActions"

const DetailsPanelCloseButton = ({ className }: { className?: string }) => {
	const details = useDetailsState()

	return (
		<Button
			variant="ghost"
			size="icon"
			onClick={() => {
				details.close()
			}}
			className={cn(className)}
		>
			<XIcon className="size-4" />
		</Button>
	)
}

const DetailsSectionHeader = forwardRef<
	HTMLDivElement,
	React.HTMLProps<HTMLDivElement> & { icon?: LucideIcon }
>(({ children, className, icon: Icon, ...props }, ref) => (
	<div
		className="flex items-center gap-2 text-xl font-bold text-foreground-strong"
		{...props}
	>
		{Icon && <Icon className="size-6" />}
		{children}
	</div>
))
DetailsSectionHeader.displayName = "DetailsSectionHeader"

const DetailsTabs = forwardRef<
	React.ElementRef<typeof Tabs>,
	React.ComponentPropsWithoutRef<typeof Tabs>
>(({ className, ...props }, ref) => (
	<Tabs ref={ref} className={cn(className)} {...props} />
))
DetailsTabs.displayName = "DetailsTabs"

const DetailsTabsList = forwardRef<
	React.ElementRef<typeof TabsList>,
	React.ComponentPropsWithoutRef<typeof TabsList>
>(({ className, ...props }, ref) => (
	<>
		<TabsList
			ref={ref}
			className={cn("flex w-full justify-start", className)}
			{...props}
		/>
		<Separator className="m-1 h-[2px] bg-border-weak" />
	</>
))
DetailsTabsList.displayName = "DetailsTabsList"

const DetailsTabsTrigger = forwardRef<
	React.ElementRef<typeof TabsTrigger>,
	React.ComponentPropsWithoutRef<typeof TabsTrigger>
>(({ className, ...props }, ref) => (
	<TabsTrigger ref={ref} className={cn("grow gap-1", className)} {...props} />
))
DetailsTabsTrigger.displayName = "DetailsTabsTrigger"

const DetailsTabsContent = forwardRef<
	React.ElementRef<typeof TabsContent>,
	React.ComponentPropsWithoutRef<typeof TabsContent>
>(({ className, ...props }, ref) => (
	<TabsContent ref={ref} className={cn("py-3", className)} {...props} />
))
DetailsTabsContent.displayName = "DetailsTabsContent"

// ============================================================================
// Panel-specific components
// ============================================================================

const DetailsPanel = forwardRef<
	HTMLDivElement,
	React.HTMLProps<HTMLDivElement>
>(({ children, className, ...props }, ref) => (
	<div
		ref={ref}
		{...props}
		className={cn("relative flex flex-col gap-2 px-5 py-4", className)}
	>
		{children}
	</div>
))
DetailsPanel.displayName = "DetailsPanel"

//TODO: move this somewhere else maybe + make one for pages
function DetailsPanelSkeleton() {
	return (
		<DetailsPanel>
			<Skeleton className="h-6 w-32" />

			<Skeleton className="h-4 w-full" />
			<Skeleton className="h-4 w-3/4" />
			<Skeleton className="h-4 w-1/2" />
		</DetailsPanel>
	)
}

// ============================================================================
// Page-specific components
// ============================================================================

const DetailsPage = forwardRef<HTMLDivElement, React.HTMLProps<HTMLDivElement>>(
	({ children, className, ...props }, ref) => (
		<div
			ref={ref}
			{...props}
			className={cn("relative flex flex-col gap-2 px-6 py-4", className)}
		>
			{children}
		</div>
	),
)
DetailsPage.displayName = "DetailsPage"

export {
	DetailsPage,
	DetailsPanel,
	DetailsPanelCloseButton,
	DetailsPanelSkeleton,
	DetailsScope,
	DetailsSectionHeader,
	DetailsSubtitle,
	DetailsTabs,
	DetailsTabsContent,
	DetailsTabsList,
	DetailsTabsTrigger,
	DetailsTitle,
	DetailsTopRightActions,
}
