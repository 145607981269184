import {
	EllipsisIcon,
	MailIcon,
	PhoneIcon,
	ShoppingCart,
	UserIcon,
} from "lucide-react"

import type { Vendor } from "@/server/schemas"
import { Button } from "@/components/ui/button"
import { Chip } from "@/components/ui/chip"
import {
	DetailsCard,
	DetailsCardInfoLine,
	DetailsCardOverline,
	DetailsCardSubtitle,
	DetailsCardTitle,
} from "@/components/ui/details-card"

//TODO: Type this
interface VendorDetailsCardProps {
	vendor: Vendor
}

//TODO: Implement this with real data
export function VendorDetailsCard({ vendor }: VendorDetailsCardProps) {
	return (
		<DetailsCard>
			<DetailsCardOverline icon={ShoppingCart}>
				Vendor
			</DetailsCardOverline>
			<Button
				variant="ghost"
				size="icon"
				className="absolute right-1 top-1"
			>
				<EllipsisIcon className="size-4" />
			</Button>

			<section>
				<DetailsCardTitle>{vendor.name}</DetailsCardTitle>
				<DetailsCardSubtitle>
					{vendor.city}, {vendor.state}
				</DetailsCardSubtitle>
			</section>

			{/* TODO: Replace with real vendor tags */}
			<div className="flex flex-wrap items-center gap-2">
				<Chip variant="primary">PRIMARY</Chip>
				<Chip variant="success">NET30</Chip>
			</div>

			<section>
				<DetailsCardInfoLine icon={UserIcon}>
					{vendor.contacts[0]?.name ?? "No contact"}{" "}
					{vendor.contacts[0]?.isRepresentative && "| Rep"}
				</DetailsCardInfoLine>
				<DetailsCardInfoLine icon={MailIcon}>
					{vendor.email}
				</DetailsCardInfoLine>
				<DetailsCardInfoLine icon={PhoneIcon}>
					{vendor.phone}
				</DetailsCardInfoLine>
			</section>
		</DetailsCard>
	)
}
