import type { LatLngTuple } from "leaflet"
import { MapContainer, Marker, Popup, TileLayer, useMap } from "react-leaflet"

import type { Location } from "@/server/schemas"

export function LocationMap({ location }: { location: Location }) {
	const position = [29.652, -82.325] as LatLngTuple
	return (
		<MapContainer
			zoom={13}
			center={position}
			scrollWheelZoom={false}
			className="col-span-6 h-[250px] max-w-[750px] grow rounded-lg border border-border shadow-sm 2xl:h-[400px]"
		>
			<TileLayer
				attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
				url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
			/>
			<Marker position={position}>
				<Popup>{location.name}</Popup>
			</Marker>
		</MapContainer>
	)
}
