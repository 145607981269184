import type { FakeEmployee } from "@/routes/_main+/resources+/employees/fake-employee"
import type { loader as employeeLoader } from "@/routes/api+/employees.$employeeId"
import type { SerializeFrom } from "@remix-run/node"
import { useSuspenseQuery } from "@tanstack/react-query"
import {
	EllipsisIcon,
	FileClock,
	Info,
	InfoIcon,
	SearchIcon,
	TimerResetIcon,
} from "lucide-react"

import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar"
import { Button } from "@/components/ui/button"
import { Chip } from "@/components/ui/chip"
import {
	DetailsPage,
	DetailsPanel,
	DetailsResponsiveRenderer,
	DetailsScope,
	DetailsSectionHeader,
	DetailsSubtitle,
	DetailsTabs,
	DetailsTabsContent,
	DetailsTabsList,
	DetailsTabsTrigger,
	DetailsTitle,
	DetailsTopRightActions,
} from "@/components/ui/details-view"
import { Input, InputSlot } from "@/components/ui/input"
import { Separator } from "@/components/ui/separator"
import { Skeleton } from "@/components/ui/skeleton"
import { Overline } from "@/components/ui/typography"
import type { DetailsViewComponent } from "@/components/shared/details/detail-view"
import { Notes } from "@/components/shared/details/notes"

import { PurchaseOrderDetailsCard } from "../purchase-order/details-card"
import { WorkOrderDetailCard } from "../work-order/details-card"

export const EmployeeDetailsView: DetailsViewComponent = (props) => {
	const { id: employeeId } = props
	const { data: employee } = useSuspenseQuery({
		queryKey: ["employee", employeeId],
		queryFn: async () => {
			const response = await fetch(`/api/employees/${employeeId}`)

			if (!response.ok) {
				if (response.status === 404) {
					throw new Error(
						`Employee ${employeeId} could not be found. Please check the ID and try again.`,
					)
				}
				throw new Error("Failed to fetch customer")
			}

			const { employee } = (await response.json()) as SerializeFrom<
				typeof employeeLoader
			>

			return employee
		},
	})

	return (
		<DetailsResponsiveRenderer
			detailPage={<EmployeeDetailsPanel employee={employee} />} // TODO: Make this a page
			detailPanel={<EmployeeDetailsPanel employee={employee} />}
		/>
	)
}

function EmployeeDetailsPage({
	employee,
}: {
	employee: FakeEmployee /* TODO: Type this */
}) {
	return (
		<DetailsPage>
			<DetailsTopRightActions>
				<Button variant="outline">Add Contact</Button>
				<Button variant="ghost" size="icon">
					<EllipsisIcon className="size-4" />
				</Button>
			</DetailsTopRightActions>
			<DetailsScope feature="employee" />
			<div className="flex items-center gap-2">
				<section>
					<DetailsTitle>{employee.name}</DetailsTitle>
					<DetailsSubtitle>{employee.occupation}</DetailsSubtitle>
				</section>
			</div>
			<div className="mt-2 flex gap-1">
				<Chip variant="primary">PRIMARY</Chip>
				<Chip variant="info">HARD TO FIND</Chip>
				<Chip variant="info">NON-BILLABLE</Chip>
			</div>
			<Separator />
			<p className="text-xl font-bold">Info</p>
			<div className="grid grid-cols-2 gap-x-8 gap-y-3 p-3 2xl:grid-cols-3">
				<section>
					<Overline>Phone</Overline>
					<p className="font-semibold">{employee.phone}</p>
				</section>
				<section>
					<Overline>Email</Overline>
					<p className="font-semibold">{employee.email}</p>
				</section>
				<section>
					<Overline>Hire Date</Overline>
					<p className="font-semibold">{employee.hireDate}</p>
				</section>
				<section>
					<Overline>Role</Overline>
					<p className="font-semibold">{employee.role}</p>
				</section>
			</div>
		</DetailsPage>
	)
}

function EmployeeDetailsPanel({
	employee,
}: {
	employee: FakeEmployee /* TODO: Type this */
}) {
	return (
		<DetailsPanel>
			<DetailsTopRightActions>
				<Button variant="ghost" size="icon">
					<EllipsisIcon className="size-4" />
				</Button>
			</DetailsTopRightActions>
			<DetailsScope feature="employee" />
			<div className="flex items-center gap-2">
				<section>
					<DetailsTitle>{employee.name}</DetailsTitle>
					<DetailsSubtitle>{employee.occupation}</DetailsSubtitle>
				</section>
			</div>
			<div className="mt-2 flex gap-1">
				<Chip variant="primary">PRIMARY</Chip>
				<Chip variant="info">HARD TO FIND</Chip>
				<Chip variant="info">NON-BILLABLE</Chip>
			</div>
			<div className="flex items-center justify-between gap-2 py-3">
				<Avatar className="mr-3 size-24 shadow-lg">
					<AvatarImage src={employee.img} />
					<AvatarFallback className="text-4xl font-bold">{`${employee.name.charAt(0)}${employee.name.split(" ")[1]?.charAt(0)}`}</AvatarFallback>
				</Avatar>
				<div className="flex grow flex-col gap-2">
					<div className="flex items-center justify-between">
						<Overline>Role</Overline>
						<span className="capitalize">{employee.role}</span>
					</div>
					<div className="flex items-center justify-between">
						<Overline>Emp Type</Overline>
						<span className="capitalize">
							{employee.occupation}
						</span>
					</div>
					<div className="flex items-center justify-between">
						<Overline>Shift</Overline>
						<span className="capitalize">MTWRF95</span>
						{/* TODO: Replace with real data */}
					</div>

					<div className="flex items-center justify-between">
						<Overline>Hire Date</Overline>
						<span className="capitalize">{employee.hireDate}</span>
					</div>
				</div>
			</div>

			<DetailsTabs defaultValue="info">
				<DetailsTabsList>
					<DetailsTabsTrigger value="info">
						<InfoIcon />
					</DetailsTabsTrigger>
					<DetailsTabsTrigger value="history">
						<TimerResetIcon />
					</DetailsTabsTrigger>
				</DetailsTabsList>
				<DetailsTabsContent value="info">
					<InfoSection employee={employee} />
				</DetailsTabsContent>
				<DetailsTabsContent value="history">
					<HistorySection />
				</DetailsTabsContent>
			</DetailsTabs>
		</DetailsPanel>
	)
}

function InfoSection({ employee }: { employee: FakeEmployee }) {
	return (
		<div className="flex flex-col gap-4">
			<Notes notes={undefined} />
			<DetailsSectionHeader>Info</DetailsSectionHeader>

			<div className="grid grid-cols-2 gap-4">
				<section className="flex flex-col">
					<Overline>Phone 1</Overline>
					<p className="text-foreground-strong">{employee.phone}</p>
				</section>
				<section className="flex flex-col">
					<Overline>Phone 2</Overline>
					<p className="text-foreground-strong">{employee.phone}</p>
				</section>
				<section className="col-span-2 flex flex-col">
					<Overline>Email</Overline>
					<p className="text-foreground-strong">{employee.email}</p>
				</section>
				<section className="col-span-2 flex flex-col">
					<Overline>Address</Overline>
					<p className="text-foreground-strong">
						2697 Westshore Ave, #43
					</p>
					<p className="text-foreground-strong">Seattle, WA 98126</p>
				</section>
				<section className="col-span-2 flex flex-col">
					<Overline>DOB</Overline>
					<p className="text-foreground-strong">8/26/85</p>
					<p className="text-foreground-strong">Seattle, WA 98126</p>
				</section>
				<section className="flex flex-col">
					<Overline>Emergency Contact</Overline>
					<p className="text-foreground-strong">
						Sally Beth Smergens
					</p>
				</section>
				<section className="flex flex-col">
					<Overline>Emergency Phone #</Overline>
					<p className="text-foreground-strong">(703) 555-1212</p>
				</section>
				<section className="flex flex-col">
					<Overline>Hire Date</Overline>
					<p className="text-foreground-strong">
						{employee.hireDate}
					</p>
				</section>
				<section className="flex flex-col">
					<Overline>Role</Overline>
					<p className="text-foreground-strong">{employee.role}</p>
				</section>
			</div>
		</div>
	)
}

function HistorySection() {
	return (
		<div className="flex flex-col gap-4">
			<DetailsSectionHeader icon={FileClock}>
				Employee History
			</DetailsSectionHeader>
			<Input placeholder="Search...">
				<InputSlot>
					<SearchIcon />
				</InputSlot>
			</Input>
			<PurchaseOrderDetailsCard />
			<WorkOrderDetailCard workOrder={{}} />
			<WorkOrderDetailCard workOrder={{}} />
		</div>
	)
}
