import { forwardRef, useState } from "react"

import { Button } from "../../ui/button"

interface NotesProps {
	notes: string[] | undefined
}

export function Notes({ notes }: { notes: NotesProps["notes"] }) {
	const [seeAll, setSeeAll] = useState(false)

	if (!notes || notes.length === 0)
		return <NotesContainer>No notes</NotesContainer>

	return (
		<NotesContainer>
			{seeAll ?
				<NotesExpanded notes={notes} setSeeAll={setSeeAll} />
			:	<NotesCollapsed notes={notes} setSeeAll={setSeeAll} />}
		</NotesContainer>
	)
}

const Note = forwardRef<
	HTMLParagraphElement,
	React.HTMLProps<HTMLParagraphElement>
>(({ children, ...props }, ref) => (
	<p ref={ref} {...props} className="text-foreground">
		{children}
	</p>
))
Note.displayName = "Note"

function NotesExpanded({ notes, setSeeAll }: NotesCollapsedProps) {
	return (
		<div className="flex flex-col gap-2">
			{notes.map((note, index) => (
				<Note key={index}>{note}</Note>
			))}
			<Button variant="link" onClick={() => setSeeAll(false)} size="none">
				Hide
			</Button>
		</div>
	)
}

interface NotesCollapsedProps {
	notes: string[]
	setSeeAll: (seeAll: boolean) => void
}

function NotesCollapsed({ notes, setSeeAll }: NotesCollapsedProps) {
	return (
		<div className="flex flex-col gap-2">
			<Note>{notes[0]}</Note>
			{notes.length > 1 && (
				<Button
					variant="link"
					onClick={() => setSeeAll(true)}
					size="none"
				>
					See all notes ({notes.length})
				</Button>
			)}
		</div>
	)
}

function NotesContainer({ children }: { children?: React.ReactNode }) {
	return (
		<div className="flex max-h-[500px] flex-col gap-2 overflow-y-auto rounded-md border border-[#e7dfb5] bg-[#FFF599] p-3 text-asphalt-900 [scrollbar-width:thin]">
			{children}
		</div>
	)
}
