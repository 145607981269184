import type { loader as equipmentLoader } from "@/routes/api+/equipment.$equipmentId"
import type { SerializeFrom } from "@remix-run/node"
import { useSuspenseQuery } from "@tanstack/react-query"
import {
	BadgeCheckIcon,
	DollarSignIcon,
	EllipsisIcon,
	InfoIcon,
	SearchIcon,
	TimerResetIcon,
} from "lucide-react"

import { Button } from "@/components/ui/button"
import { Chip } from "@/components/ui/chip"
import {
	DetailsPage,
	DetailsPanel,
	DetailsResponsiveRenderer,
	DetailsScope,
	DetailsSectionHeader,
	DetailsSubtitle,
	DetailsTabs,
	DetailsTabsContent,
	DetailsTabsList,
	DetailsTabsTrigger,
	DetailsTitle,
	DetailsTopRightActions,
} from "@/components/ui/details-view"
import { Input, InputSlot } from "@/components/ui/input"
import { Separator } from "@/components/ui/separator"
import { Overline } from "@/components/ui/typography"
import type { DetailsViewComponent } from "@/components/shared/details/detail-view"
import { Notes } from "@/components/shared/details/notes"

import { WorkOrderDetailCard } from "../work-order/details-card"

type FakeEquipment = SerializeFrom<typeof equipmentLoader>["equipment"]

export const EquipmentDetailsView: DetailsViewComponent = (props) => {
	const { id: equipmentId } = props
	const { data: equipment } = useSuspenseQuery({
		queryKey: ["equipment", equipmentId],
		queryFn: async () => {
			const response = await fetch(`/api/equipment/${equipmentId}`)

			if (!response.ok) {
				if (response.status === 404) {
					throw new Error(
						`Equipment ${equipmentId} could not be found. Please check the ID and try again.`,
					)
				}
				throw new Error("Failed to fetch equipment")
			}

			const { equipment } = (await response.json()) as SerializeFrom<
				typeof equipmentLoader
			>
			return equipment
		},
	})

	return (
		<DetailsResponsiveRenderer
			detailPage={<EquipmentDetailsPanel equipment={equipment} />} //TODO: Make this a page
			detailPanel={<EquipmentDetailsPanel equipment={equipment} />}
		/>
	)
}

// ============================================================================
// Page
// ============================================================================

function EquipmentDetailsPage({ equipment }: { equipment: FakeEquipment }) {
	return (
		<DetailsPage>
			<DetailsTopRightActions>
				<Button variant="outline">Add Contact</Button>
				<Button variant="ghost" size="icon">
					<EllipsisIcon className="size-4" />
				</Button>
			</DetailsTopRightActions>
			<DetailsScope feature="equipment" />
			<section>
				<DetailsTitle>
					{equipment.year} {equipment.manufacturer} {equipment.model}
				</DetailsTitle>
				<DetailsSubtitle>
					{equipment.vin} | {equipment.licenseNumber}
				</DetailsSubtitle>
			</section>
			{/* TODO: Replace with real equipment tags */}
			<div className="flex gap-1">
				<Chip variant="primary">PRIMARY</Chip>
				<Chip variant="info">HARD TO FIND</Chip>
				<Chip variant="info">NON-BILLABLE</Chip>
			</div>
			<section className="my-2 grid grid-cols-[auto_1fr] gap-4">
				<div className="aspect-[3/2] h-28 rounded-md bg-fiveo-150" />
				<div className="space-y-3">
					<div className="flex flex-col gap-1 leading-none">
						<Overline>Owner</Overline>
						<span>{equipment.registeredOwner}</span>
					</div>
					<div className="flex flex-col gap-1 leading-none">
						<Overline>Base Location</Overline>
						<span>{equipment.baseLocation}</span>
					</div>
				</div>
			</section>
			<DetailsTabs defaultValue="info">
				<DetailsTabsList className="w-fit">
					<DetailsTabsTrigger value="info">
						<InfoIcon />
						Information
					</DetailsTabsTrigger>
					<DetailsTabsTrigger value="history">
						<TimerResetIcon />
						History
					</DetailsTabsTrigger>
				</DetailsTabsList>
				<DetailsTabsContent value="info" />
				<DetailsTabsContent value="history" />
			</DetailsTabs>
		</DetailsPage>
	)
}

// ============================================================================
// Panel
// ============================================================================

function EquipmentDetailsPanel({ equipment }: { equipment: FakeEquipment }) {
	return (
		<DetailsPanel>
			<DetailsTopRightActions>
				<Button variant="ghost" size="icon">
					<EllipsisIcon className="size-4" />
				</Button>
			</DetailsTopRightActions>

			<DetailsScope feature="equipment" />
			<section>
				<DetailsTitle>
					{equipment.year} {equipment.manufacturer} {equipment.model}
				</DetailsTitle>
				<DetailsSubtitle>
					{equipment.vin} | {equipment.licenseNumber}
				</DetailsSubtitle>
			</section>
			{/* TODO: Replace with real equipment tags */}
			<div className="flex gap-1">
				<Chip variant="primary">PRIMARY</Chip>
				<Chip variant="info">HARD TO FIND</Chip>
				<Chip variant="info">NON-BILLABLE</Chip>
			</div>
			<section className="flex gap-2">
				<div className="aspect-[3/2] h-28 rounded-md bg-fiveo-150" />
				<section className="flex grow flex-col gap-4">
					<div className="flex justify-between">
						<div className="flex flex-col gap-1 leading-none">
							<Overline>Owner</Overline>
							<span className="text-foreground-strong">
								{equipment.registeredOwner}
							</span>
						</div>
						<div className="flex flex-col gap-1 leading-none">
							<Overline>Base Location</Overline>
							<span className="text-foreground-strong">
								{equipment.baseLocation}
							</span>
						</div>
					</div>
					<div className="flex rounded-md border border-primary p-3">
						<div className="flex flex-1 flex-col items-center">
							<Overline>Fleet</Overline>
							<span className="text-foreground-strong">
								{equipment.fleet}
							</span>
						</div>

						<Separator
							orientation="vertical"
							className="flex-none"
						/>
						<div className="flex flex-1 flex-col items-center">
							<Overline>Group</Overline>
							<span className="text-foreground-strong">
								{equipment.group}
							</span>
						</div>
						<Separator
							orientation="vertical"
							className="flex-none"
						/>
						<div className="flex flex-1 flex-col items-center">
							<Overline>Unit</Overline>
							<span className="text-foreground-strong">
								{equipment.unit}
							</span>
						</div>
					</div>
				</section>
			</section>
			<DetailsTabs defaultValue="info">
				<DetailsTabsList>
					<DetailsTabsTrigger value="info">
						<InfoIcon />
					</DetailsTabsTrigger>
					<DetailsTabsTrigger value="financial">
						<DollarSignIcon />
					</DetailsTabsTrigger>
					<DetailsTabsTrigger value="history">
						<TimerResetIcon />
					</DetailsTabsTrigger>
				</DetailsTabsList>
				<DetailsTabsContent value="info">
					<InfoSection equipment={equipment} />
				</DetailsTabsContent>
				<DetailsTabsContent value="financial">
					Financial Info goes here
				</DetailsTabsContent>
				<DetailsTabsContent value="history">
					<HistorySection />
				</DetailsTabsContent>
			</DetailsTabs>
		</DetailsPanel>
	)
}

function InfoSection({ equipment }: { equipment: FakeEquipment }) {
	return (
		<div className="flex flex-col gap-4">
			<Notes notes={undefined} />
			<DetailsSectionHeader>Info</DetailsSectionHeader>
			<div className="grid grid-cols-2 gap-4">
				<div className="flex flex-col gap-1">
					<Overline>Reg Owner Id</Overline>
					<span className="text-foreground-strong">
						{equipment.registeredOwner}
					</span>
				</div>
				<div className="flex flex-col gap-1">
					<Overline>Weight Class</Overline>
					<span className="text-foreground-strong">
						{equipment.weightClass}
					</span>
				</div>
				<div className="flex flex-col gap-1">
					<Overline>Reg State</Overline>
					<span className="text-foreground-strong">OR</span>
					{/* TODO: REG STATE DATA? */}
				</div>
				<div className="flex flex-col gap-1">
					<Overline>Reg Expiry</Overline>
					<span className="text-foreground-strong">
						{equipment.registrationExpiry}
					</span>
				</div>
				<div className="col-span-2 flex justify-between">
					<Overline>Interstate Reciprocity Plate?</Overline>
					<span className="text-foreground-strong">
						{equipment.reciprocityPlate}
					</span>
				</div>
				<div className="col-span-2 flex justify-between">
					<Overline>Offroad?</Overline>
					<span className="text-foreground-strong">
						No
						{/* TODO: Offroad data? */}
					</span>
				</div>
				<div className="flex flex-col gap-1">
					<Overline>Default Repair Site</Overline>
					<span className="text-foreground-strong">
						CORVALLIS
						{/* TODO: Default Repair Site data? */}
					</span>
				</div>
				<div className="flex flex-col gap-1">
					<Overline>Assignment</Overline>
					<span className="text-foreground-strong">
						Long Haul
						{/* TODO: Assignment data? */}
					</span>
				</div>
				<div className="flex flex-col gap-1">
					<Overline>Driver Name</Overline>
					<span className="text-foreground-strong">
						Tom Coleman
						{/* TODO: Driver data? */}
					</span>
				</div>
				<div className="flex flex-col gap-1">
					<Overline>Driver Email</Overline>
					<span className="text-foreground-strong">
						tom@tomco.com
						{/* TODO: Driver data? */}
					</span>
				</div>
			</div>
			<div className="relative flex w-full rounded-md border-2 border-[#e8c14b] p-2">
				<BadgeCheckIcon className="mr-3 size-8 fill-[#d0a21a] text-white" />
				<div className="flex flex-col gap-2">
					<p className="text-lg text-foreground-strong">
						50,000 mile / 5 year warranty
					</p>
					<p className="text-sm text-foreground-weak">
						Started 11/1/15, Original parts only
					</p>
				</div>
			</div>
		</div>
	)
}

function HistorySection() {
	return (
		<div className="flex flex-col gap-4">
			<DetailsSectionHeader>Equipment History</DetailsSectionHeader>
			<Input placeholder="Search...">
				<InputSlot>
					<SearchIcon />
				</InputSlot>
			</Input>
			<WorkOrderDetailCard workOrder={{}} />
			<WorkOrderDetailCard workOrder={{}} />
		</div>
	)
}
