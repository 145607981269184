import type { ReactNode } from "react"
import { createContext, useContext, useState } from "react"

import { cn } from "@/lib/utils/classnames"
import { Page } from "@/components/ui/page"

import { Details } from "../details"
import { NothingSelected } from "../details/nothing-selected"
import { useDetailsState } from "../details/use-details-state"

export type ListView = "queue" | "board" | "grid"

interface ListViewContextValue {
	view: ListView
	availableViews: ListView[]
	setView: (view: ListView) => void
}

const ListViewContext = createContext<ListViewContextValue>({
	view: "queue",
	availableViews: ["queue"],
	setView: () => undefined,
})

type ViewComponents = {
	queue: ReactNode
} & {
	[ViewKey in Exclude<ListView, "queue">]?: ReactNode
}

export function useListView() {
	const context = useContext(ListViewContext)
	if (!context) {
		throw new Error("useListView must be used within a ListViewProvider")
	}
	return context
}

interface LayoutProps {
	listViews: ViewComponents
	initialView?: ListView
	onViewChange?: (view: ListView) => void
}

export function Layout({
	listViews,
	initialView = "queue",
	onViewChange,
}: LayoutProps) {
	const details = useDetailsState()
	const [currentView, setCurrentView] = useState<ListView>(initialView)

	const availableViews = Array.from(
		new Set(["queue", ...Object.keys(listViews)]),
	).sort() as ListView[]

	const handleViewChange = (view: ListView) => {
		onViewChange?.(view)
		setCurrentView(view)
	}

	return (
		<ListViewContext.Provider
			value={{
				view: currentView,
				availableViews,
				setView: handleViewChange,
			}}
		>
			{/* List Mobile */}
			<Page className={cn("w-full md:hidden")}>{listViews.queue}</Page>

			{/* List Desktop */}
			<Page
				className={cn(
					"max-md:hidden",
					currentView === "queue" ?
						"shrink-0 grow-0 sm:w-[320px]"
					:	"grow basis-[1600px]",
				)}
			>
				{listViews[currentView]}
			</Page>

			{/* Detail Container */}
			{details.isOpen ?
				<Page
					className={cn(
						currentView === "queue" ? "grow sm:block" : (
							"min-w-96 3xl:min-w-[650px] 3xl:basis-1/2"
						),
						"max-md:absolute max-md:inset-0 max-md:w-full",
					)}
				>
					<Details />
				</Page>
			:	<NothingSelected />}
		</ListViewContext.Provider>
	)
}
